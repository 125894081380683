import { API } from "aws-amplify";
import { checkPageSettings } from "actions/page";

export const GET_PROFILE_DETAIL_REQUEST =
  "@@profile/GET_PROFILE_DETAIL_REQUEST";
export const GET_PROFILE_DETAIL_SUCCESS =
  "@@profile/GET_PROFILE_DETAIL_SUCCESS";
export const GET_PROFILE_DETAIL_FAILURE =
  "@@profile/GET_PROFILE_DETAIL_FAILURE";

export function getProfile() {
  return async (dispatch) => {
    dispatch({
      type: GET_PROFILE_DETAIL_REQUEST,
    });
    dispatch(checkPageSettings(true));
    try {
      const response = await API.get("cmsAPI", "/profile");
      dispatch({
        type: GET_PROFILE_DETAIL_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_PROFILE_DETAIL_FAILURE,
        payload: error,
      });
    }
  };
}

export const SET_SELECTED_SITE = "@@profile/SET_SELECTED_SITE";

export function setSelectedSite(value) {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTED_SITE,
      payload: value,
    });
  };
}

export const CLEAR_PROFILE = "@@profile/CLEAR_PROFILE";

export function clearProfile() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_PROFILE,
    });
  };
}
