import {
  GET_VERSION_REQUEST,
  GET_VERSION_SUCCESS,
  GET_VERSION_FAILURE,
  RECONCILE_VERSION,
  CLEAR_VERSION_AVAILABLE,
} from "actions/version";
import { isEmpty } from "lodash";

const initialDetails = {
  version: "",
  description: "",
  maintenance: false,
};

const initialState = {
  loading: false,
  error: {},
  details: initialDetails,
  lastDetails: initialDetails,
  isNewVersion: false,
};

export default function version(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VERSION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_VERSION_SUCCESS: {
      const lastDetails = isEmpty(state.lastDetails.version)
        ? state.details
        : state.lastDetails;
      const details = payload;

      let isNewVersion = false;

      if (lastDetails && details) {
        const lastDetailsList = lastDetails.version
          .split(".")
          .map((it) => Number(it));
        const detailsList = details.version.split(".").map((it) => Number(it));
        if (lastDetailsList.length > 2) {
          if (
            detailsList[0] > lastDetailsList[0] ||
            detailsList[1] > lastDetailsList[1]
          ) {
            isNewVersion = true;
          }
        }
      }

      return {
        ...state,
        loading: false,
        lastDetails,
        details,
        isNewVersion,
        maintenance: details?.maintenance || false,
      };
    }

    case GET_VERSION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case RECONCILE_VERSION: {
      return {
        ...state,
        lastDetails: state.details,
        isNewVersion: false,
      };
    }

    case CLEAR_VERSION_AVAILABLE: {
      return {
        ...state,
        isNewVersion: false,
      };
    }

    default:
      return state;
  }
}
