import { Box } from "@mui/material";
import { SiteSelector } from "empower-energy-owner-components";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedSite } from "actions/profile";
import { clearSites } from "actions/site";

const SiteSelect = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const onChange = (e) => {
    dispatch(clearSites());
    dispatch(setSelectedSite(e.target.value));
  };

  return (
    <Box mb={1}>
      <SiteSelector
        options={profile?.sites}
        value={profile?.selectedSite}
        onChange={onChange}
      />
    </Box>
  );
};

export default SiteSelect;
