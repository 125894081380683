import { Box, Typography } from "@mui/material";
import { SessionContainer, SessionLogo } from "empower-energy-owner-components";

const styles = () => {
  return {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    backgroundColor: "white",
  };
};

const Maintenance = () => {
  return (
    <Box sx={styles}>
      <SessionContainer>
        <Box px={2} className="flex-col items-justify-center">
          <Typography variant="h5" textAlign="center">
            The Empower mobile app is currently unavailable for scheduled maintenance.
            We hope to be back soon…
          </Typography>

          <Box my={3} className="items-justify-center">
            <SessionLogo />
          </Box>
        </Box>
      </SessionContainer>
    </Box>
  );
};

export default Maintenance;
